body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* fixing losing styles in radix themes. TODO: find better way */
  --scaling: 1;
  --space-4: calc(16px * var(--scaling));
}

.input-no-border:focus {
  outline: none;
}

/* CampaignForm */

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.table th,
.table td {
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table th {
  background-color: #ffffff;
  padding: 15px;
}

.table tr:nth-child(even) {
  background-color: #ffffff;
}