.title {
  color: black;
  font-size: 16px;
}

.inputField {
  color: black;
  background-color: white;
  margin-top: 15px;
  border: 1px solid gray;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  width: calc(100vw / 5);
}

.contentRow {
  display: flex;
}