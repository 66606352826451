.contentMetricsArea {
  background-color: #F4F4F4;
  padding: 20px;
  margin-bottom: 25px;
}

.areaTitle {
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.inputField {
  color: black;
  background-color: white;
  margin-top: 5px;
  border: 1px solid gray;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  width: calc(100vw / 5);
}

.contentRow {
  display: flex;
  margin-top: 15px;
}

.contentField {
  display: flex;
  margin-top: 15px;
}

.datepicker-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

.shadow-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}