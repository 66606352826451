.contentMetricsArea {
 background-color: #F4F4F4;
 padding: 20px;
}

.areaTitle {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  
  .inputField {
    color: black;
    background-color: white;
    margin-top: 5px;
    border: 1px solid gray;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    width: calc(100vw / 5);
  }
  
  .contentRow {
    display: flex;
    margin-top: 15px;
  }

  .contentField {
    display: flex;
    margin-top: 15px;
  }